import { ViewerAsset } from '@models/viewer-asset';
import { createAction, props } from '@ngrx/store';


export enum VIEWER_ACTIONS {
  loadAssets = '[Viewer] Load Assets',
  loadAsset = '[Viewer] Load Asset',
  resetAssets = '[Viewer] Reset Assets',
  setAssets = '[Viewer] Set Assets',
  setMessages = '[Viewer] Set Messages',
  setCurrentAsset = '[Viewer] Set Current Asset',
}

export const loadAssets = createAction(VIEWER_ACTIONS.loadAssets);
export const loadAsset = createAction(VIEWER_ACTIONS.loadAsset, props<{ assetId: string }>());
export const resetAssets = createAction(VIEWER_ACTIONS.resetAssets);
export const setAssets = createAction(VIEWER_ACTIONS.setAssets, props<{ viewerAssets: ViewerAsset[] }>());
export const setMessages = createAction(VIEWER_ACTIONS.setMessages, props<{ messages: string[] }>());
export const setCurrentAsset = createAction(VIEWER_ACTIONS.setCurrentAsset, props<{ currentAsset: ViewerAsset }>());
