import { ViewerAsset } from '@models/viewer-asset';
import {
  createReducer,
  on,
  Action,
} from '@ngrx/store';


import { loadAssets, resetAssets, setAssets, setMessages, setCurrentAsset } from './viewer.actions';

export const viewerStateFeatureKey = 'viewer';

export interface ViewerState {
  isLoading: boolean;
  hasLoaded: boolean;
  hasError: boolean;
  viewerAssets: ViewerAsset[];
  messages: string[];
  currentAsset: ViewerAsset;
}

export const initialState: ViewerState = {
  isLoading: false,
  hasLoaded: false,
  hasError: false,
  viewerAssets: [],
  messages: [],
  currentAsset: null,
};

const viewerReducer = createReducer(
  initialState,
  on(resetAssets, state => ({ ...state, isLoading: true })),
  on(loadAssets, state => ({ ...state, hasLoaded: false, viewerAssets: [], hasError: false, messages: [] })),
  on(setAssets, (state, { viewerAssets }) => ({ ...state, hasLoaded: true, isLoading: false, viewerAssets })),
  on(setMessages, (state, { messages }) => ({ ...state, hasError: true, messages })),
  on(setCurrentAsset, (state, { currentAsset }) => ({ ...state, currentAsset })),
);

export function reducer(state: ViewerState | undefined, action: Action) {
  return viewerReducer(state, action);
}
